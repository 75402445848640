import React, { useState, useEffect, useRef } from 'react';
import '../styles/App.css';
import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import { devopsRequest, directoryRequest, loginRequest } from '../authConfig';
import { RequestReport,RequestURSReport,RequestTraceReport, SendMail, PollReport } from '../devops';
import { OrganizationDropDown } from './OrganizationDropDown';
import { ProjectDropDown } from './ProjectDropDown';
import  {QueryDropDown}  from './QueryDropDown';
import { PlanDropDown } from './PlanDropDown';
import { SuiteDropDown } from './SuiteDropDown';
import { useToggleLoading, useIsLoading } from '../LoadingContext';
import { useAuthorization} from '../AuthorizationContext';
import { useInfo, useInfoUpdate } from '../InformativeContext';
import ReactLoading from "react-loading";
import { RootQueryDropDown } from './RootQueryDropDown';


const ReportsPage = () => {

    const { instance, accounts } = useMsal();
    const [selectedOrg, setSelectedOrg] = useState({});
    const [selectedProj, setSelectedProj] = useState({});
    const [selectedPlan, setSelectedPlan] = useState({});
    const [selectedSuite, setSelectedSuite] = useState({});
    const [selectedRootTrace, setSelectedRootTrace] = useState({});
    const [selectedRootURS, setSelectedRootURS] = useState({});
    const [selectedQueryURS1, setSelectedQueryURS1] = useState({});
    const [selectedQueryTrace, setSelectedQueryTrace] = useState({});
    const isVerifyed = useAuthorization()
    const info = useInfo()
    const updateInfo = useInfoUpdate()

    const updateSelectedOrg = (data) => {
        console.log("HER")
        console.log(accounts)
        console.log(instance)
        setSelectedOrg(data)
    }

    const updateSelectedRootURS = (data) => {
        setSelectedRootURS(data)
    }
    const updateSelectedRootTrace = (data) => {
        setSelectedRootTrace(data)
    }

    const updateSelectedQueryURS1 = (data) => {
        setSelectedQueryURS1(data)
    }
    const updateSelectedQueryTrace = (data) => {
        setSelectedQueryTrace(data)
    }
    const updateSelectedProject = (data) => {
        setSelectedProj(data)
    }

    const updateSelectedPlan = (data) => {
        setSelectedPlan(data)
    }

    const updateSelectedSuite = (data) => {
        setSelectedSuite(data)
    }

    const isLoading = useIsLoading()
    const toggleIsLoading = useToggleLoading()


    const requestPlanReport = () => 
    {
        if (!isVerifyed) return;
        instance
        .acquireTokenSilent({
            ...devopsRequest,
            account: accounts[0],
        })
        .then(async (response) => {
            RequestReport(selectedOrg,selectedProj,selectedPlan,0,accounts[0].username,accounts[0].tenantId, response.accessToken)
        }).catch(error => {
            if (error.errorCode === "consent_required"
            || error.errorCode === "interaction_required"
            || error.errorCode === "login_required") {
                instance.acquireTokenRedirect({
                    ...loginRequest,
                    account: accounts[0],
                });
            }
        });
    }

    const requestSuiteReport = () => 
    {
        if (!isVerifyed) return;
        instance
        .acquireTokenSilent({
            ...devopsRequest,
            account: accounts[0],
        })
        .then(async (response) => {
            RequestReport(selectedOrg,selectedProj,selectedPlan,selectedSuite,accounts[0].username, accounts[0].tenantId, response.accessToken)
        }).catch(error => {
            if (error.errorCode === "consent_required"
            || error.errorCode === "interaction_required"
            || error.errorCode === "login_required") {
                instance.acquireTokenRedirect({
                    ...loginRequest,
                    account: accounts[0],
                });
            }
        });
    } 

    const requestURSReport = () => 
    {
        if (!isVerifyed) return;
        instance
        .acquireTokenSilent({
            ...devopsRequest,
            account: accounts[0],
        })
        .then(async (response) => {
           await RequestURSReport(selectedOrg,selectedProj,selectedQueryURS1,accounts[0].username, accounts[0].tenantId, response.accessToken)
        }).catch(error => {
            if (error.errorCode === "consent_required"
            || error.errorCode === "interaction_required"
            || error.errorCode === "login_required") {
                instance.acquireTokenRedirect({
                    ...loginRequest,
                    account: accounts[0],
                });
            }
        })
    }

    const requestTraceReport = () => 
    {
        if (!isVerifyed) return;
        instance
        .acquireTokenSilent({
            ...devopsRequest,
            account: accounts[0],
        })
        .then(async (response) => {
           await RequestTraceReport(selectedOrg,selectedProj,selectedQueryTrace,selectedPlan,accounts[0].username, accounts[0].tenantId, response.accessToken)
        }).catch(error => {
            if (error.errorCode === "consent_required"
            || error.errorCode === "interaction_required"
            || error.errorCode === "login_required") {
                instance.acquireTokenRedirect({
                    ...loginRequest,
                    account: accounts[0],
                });
            }
        })/*.then(async (response) => {
            await SendMail(accounts[0].username, false)
            });*/         
    }

     const checkForNoPlans = () => 
    {
        if (isLoading) return true;
        if (selectedPlan === null || Object.keys(selectedPlan).length === 0) return true;
        if (selectedPlan?.area.name.includes('Locked')) return true;
        else return false;
    }

    const checkForNoSuite = () => 
    {
        if (isLoading) return true;
        if (selectedSuite === null || Object.keys(selectedSuite).length === 0) return true;
        if (selectedSuite?.areapath?.includes('Locked')) return true;
        else return false;
    }

    const checkForLockedPlans = () => 
    {
        if (selectedPlan === null || selectedPlan === undefined) return 'hidden';
        if (selectedPlan?.area?.name?.includes("Locked")) return 'visible';
        else return 'hidden';
    }

    const checkForLockedSuite = () => 
    {
        if (selectedSuite === null || selectedSuite === undefined) return 'hidden';
        if (selectedSuite?.areapath?.includes('Locked')) return 'visible';
        else return 'hidden';
    } 

    const checkForNoQueryURS = () => 
    {
        if (isLoading) return true;
        if (selectedQueryURS1 === null || Object.keys(selectedQueryURS1).length === 0) return true;
        else return false;
    } 
    const checkForNoQueryTrace = () => 
    {
        if (isLoading) return true;
        if (selectedQueryTrace === null || Object.keys(selectedQueryTrace).length === 0) return true;
        else return false;
    }
    return (
       
         <div>
            {info !== undefined ? <>
                        {info.type == 'error' ? <span style={{color: 'red'}}>{info.message}</span> : <span style={{color: 'green'}}>{info.message}</span>}</>:""}
            {console.log(info)}
            {isVerifyed ?
            <div>
            <br/>
            <form>
                <span>Organization</span>
                <OrganizationDropDown setSelectedOrg={updateSelectedOrg} />
                <span>Project</span>
                <ProjectDropDown selectedOrg={selectedOrg} setSelectedProj={updateSelectedProject} />
                <span>Plan</span>
                <PlanDropDown selectedOrg={selectedOrg} selectedProject={selectedProj} setSelectedPlan={updateSelectedPlan} setSelectedSuite={updateSelectedSuite} />
                <span>Suite</span>
                <SuiteDropDown selectedOrg={selectedOrg} selectedProject={selectedProj} selectedPlan={selectedPlan} setSelectedSuite={updateSelectedSuite} />
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{padding: '10px'}}>
                {!checkForNoPlans() ?
                <Button variant='success' disabled={checkForNoPlans()} onClick={requestPlanReport}>Plan Report</Button>
                : <Button variant='dark' disabled={checkForNoPlans()} onClick={requestPlanReport}>Plan Report</Button>}
                <div style={{textAlign: 'center', marginTop: '5px', visibility: checkForLockedPlans()}}>Locked</div>
                </div>
                <div style={{padding: '10px'}}>
                 {!checkForNoSuite() ?
                <Button variant='success' disabled={checkForNoSuite()} onClick={requestSuiteReport}>Suite Report</Button>
                : <Button variant='dark' disabled={checkForNoSuite()} onClick={requestSuiteReport}>Suite Report</Button>
                 }
                <div style={{textAlign: 'center', marginTop: '5px', visibility: checkForLockedSuite()}}>Locked</div>
                </div>
                </div>
            </form>
            <div id='querysection' style={{display: "flex",
            alignItems: "center",
            padding: 20,
            justifyContent: 'center'} }>
                <div id='urs' style={{display: "flex", boxShadow: "0 0 3px 2px #cec7c759",
            alignItems: "center",
            padding: 20,
            margin: 20,
            borderRadius: 20,
            justifyContent: 'center'} }>
            <form>
                <h1>URS</h1>
                <span>Folder</span>
                <RootQueryDropDown selectedOrg={selectedOrg} selectedProject={selectedProj} setSelectedRoot={updateSelectedRootURS}/>
                <span>Query</span>
                <QueryDropDown selectedOrg={selectedOrg} selectedProject={selectedProj} selectedRoot={selectedRootURS} setSelectedQuery={updateSelectedQueryURS1}/>
                {!checkForNoQueryURS() ?
                <Button variant='success' disabled={checkForNoQueryURS()} onClick={requestURSReport}>Get Report</Button>
                : <Button variant='dark' disabled={true} onClick={requestURSReport}>Get Report</Button>
                 }
            </form>
            </div>
                <div id='trace' style={{display: "flex", boxShadow: "0 0 3px 2px #cec7c759",
            alignItems: "center",
            padding: 20,
            margin: 20,
            borderRadius: 20,
            justifyContent: 'center'} } >
            <form>
                <h1>Traceabillity Matrix</h1>
                <span>Folder</span>
                <RootQueryDropDown selectedOrg={selectedOrg} selectedProject={selectedProj} setSelectedRoot={updateSelectedRootTrace}/>
                <span>Query</span>
                <QueryDropDown selectedOrg={selectedOrg} selectedProject={selectedProj} selectedRoot={selectedRootTrace} setSelectedQuery={updateSelectedQueryTrace}/>
                {!checkForNoQueryTrace() ?
                <Button variant='success' disabled={checkForNoQueryTrace()} onClick={requestTraceReport}>Get Report</Button>
                : <Button variant='dark' disabled={true} onClick={requestTraceReport}>Get Report</Button>
                 }
            </form>
            </div>
            </div>
                <br/><br/><p><strong>{accounts.length > 0 ? <>Reports will be dispatched to: {accounts[0].username}</> : ''}</strong></p> 
                {!isLoading ? "" : <div style={{
                margin: 'auto',
                width: '50%',
                padding: '10px',
                }}><ReactLoading style={{height: 50, width: 50, margin: 'auto', fill: '#FF5733'}} type='spin'/>
                </div>}
           </div>  : ""}
              </div>
    );
}

export default ReportsPage